<template>
    <div>
        <v-toolbar color="light gray">
            <v-toolbar-title class="white--text">Tenants</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon="mdi-refresh" @click="refreshPage">
            </v-btn>
            <!-- <v-btn rounded icon dark to="/tenants/new">
          <v-icon>add</v-icon>
        </v-btn> -->
        </v-toolbar>
        <v-expansion-panels v-model="filterPanel">
            <v-expansion-panel>
                <v-expansion-panel-title>Filter</v-expansion-panel-title>
                <v-expansion-panel-text>
                    <v-card>
                        <v-card-text>
                            <v-container fluid grid-list-lg>
                                <v-row wrap>
                                    <v-col cols="12" md="3">
                                        <v-text-field v-model="nameSearch" hide-details clearable variant="outlined"
                                            label="Name" single-line></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="clearFilter()">Clear All</v-btn>
                            <v-btn color="primary" text @click="filterPanel = []">Apply</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-container>
            <v-card>
                <v-data-table dense :loading="loading" :headers="headers" :items="filteredTenants" :sort-by="['name']"
                    :sort-desc="[false]" :items-per-page="-1" item-key="_id" must-sort class="elevation-1">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn flat class="mr-2" size="medium" icon="mdi-pencil" :to="'/tenants/' + item._id">
                        </v-btn>
                        <v-btn flat class="mr-2" size="medium" icon="mdi-delete" @click="deleteTenant(item._id)">
                        </v-btn>
                    </template>
                    <template v-slot:[`item.isPremiumUI`]="{ item }"><v-icon>{{ item.isPremiumUI ? 'mdi-checkbox-marked' :
                        'mdi-checkbox-blank-outline' }}</v-icon></template>
                    <template v-slot:[`item.isABUpdates`]="{ item }"><v-icon>{{ item.isABUpdates ? 'mdi-checkbox-marked' :
                        'mdi-checkbox-blank-outline' }}</v-icon></template>
                    <template v-slot:[`item.isForcedUpdates`]="{ item }"><v-icon>{{ item.isForcedUpdates ? 'mdi-checkbox-marked' :
                        'mdi-checkbox-blank-outline' }}</v-icon></template>
                        <template v-slot:[`item.products`]="{ item }">{{ item.products ? item.products.toString() : '' }}</template>
                </v-data-table>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import axios from "axios";

export default {
    name: "TenantList",
    data() {
        return {
            loading: true,
            filterPanel: [],
            nameSearch: "",
            headers: [
                { title: "Actions", value: "actions", sortable: false },
                { title: "Name", value: "name", sortable: true },
                { title: "Premium", value: "isPremiumUI", sortable: true },
                { title: "AB Updates", value: "isABUpdates", sortable: true },
                { title: "Forced Updates", value: "isForcedUpdates", sortable: true },
                { title: "Products", value: "products", sortable: false },
            ],
            tenants: [],
            roles: [],
        };
    },
    computed: {
        filteredTenants() {
            let tenants = this.tenants;
            if (this.nameSearch) {
                var term = this.nameSearch.toLowerCase()
                tenants = tenants.filter(tenant => tenant.name.toLowerCase().includes(term))
            }
            return tenants;
        },
    },
    methods: {
        getTenants() {
            this.loading = true;
            axios
                .get(`https://whitelabel.safetelecom.net/api/tenants`)
                .then((response) => {
                    this.tenants = response.data;
                    console.log(this.items)
                    this.loading = false;
                });
        },
        deleteTenant(id) {
            confirm("Are you sure you want to delete this item?") &&
                axios
                    .delete(`https://whitelabel.safetelecom.net/api/tenants/${id}`)
                    .then(() => {
                        this.refreshPage();
                    });
        },
        refreshPage() {
            this.tenants = [];
            this.getTenants();
        },
        clearFilter() {
            this.nameSearch = "";
        },
    },
    created() {
        this.getTenants();
    },

};
</script>
  