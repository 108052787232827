import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import "@mdi/font/css/materialdesignicons.css";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import JsonCSV from 'vue-json-csv'

const vuetify = createVuetify({
  components,
  directives,
})

createApp(App).use(router)
  .use(router)
  .use(vuetify)
  .component('VueDatePicker', VueDatePicker)
  .component('downloadCsv', JsonCSV)
  .mount('#app');

