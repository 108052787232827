<template>
    <div>
        <v-toolbar dark color="light gray">
            <v-toolbar-title class="white--text">Clients</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon="mdi-table-edit">
                <v-icon icon="mdi-table-edit"></v-icon>
                <v-tooltip activator="parent">Choose Columns</v-tooltip>
                <v-menu activator="parent" :close-on-content-click="false" location="start" max-width="300px">
                    <v-card scrollable>
                        <v-card-title>Column Selection</v-card-title>
                        <v-card-text style="height: 500px; overflow-y: scroll">
                            <v-list>
                                <v-list-item v-for="header in headers" :key="header.value">
                                    <v-checkbox v-model="selectedHeaders" :label="header.title" :value="header.value"
                                        :disabled="header.value === 'serialNo'" density="compact"></v-checkbox>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>

                </v-menu>
            </v-btn>
            <v-btn icon="mdi-file-download-outline">
                <v-tooltip activator="parent">Download CSV</v-tooltip>
                <download-csv :data="formattedDataForExport" :fields="fieldsForExport" name="whitelabel_clients.csv">
                    <v-icon icon="mdi-file-download-outline"></v-icon>
                </download-csv>
            </v-btn>
            <!-- <v-btn icon="mdi-refresh" dark @click="refreshPage">
            </v-btn> -->
            <!-- <v-btn icon="mdi-plus" dark :to="{ name: 'Client', params: { id: 'new' } }">
            </v-btn> -->
        </v-toolbar>
        <v-expansion-panels v-model="filterPanel">
            <v-expansion-panel>
                <v-expansion-panel-title>Filter</v-expansion-panel-title>
                <v-expansion-panel-text>
                    <v-card>
                        <v-card-text>
                            <v-container fluid grid-list-lg>
                                <v-row wrap>
                                    <v-col cols="12" md="2">
                                        <v-text-field v-model="serialSearch" hide-details clearable variant="outlined"
                                            label="Serial Number" single-line></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field v-model="nameSearch" hide-details clearable variant="outlined"
                                            label="Name" single-line></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field v-model="phoneSearch" hide-details clearable variant="outlined"
                                            label="Phone Number" single-line></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select v-model="tenantSearch" :items="tenants" multiple clearable
                                            variant="outlined" label="Tenant"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field v-model="imeiSearch" hide-details clearable variant="outlined"
                                            label="IMEI" single-line></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field v-model="iccidSearch" hide-details clearable variant="outlined"
                                            label="ICCID" single-line></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select v-model="deviceSearch" :items="deviceNames" multiple clearable
                                            variant="outlined" label="Device Name"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select v-model="entrySearch" :items="entryPoints" multiple clearable
                                            variant="outlined" label="Entry Point"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="5">
                                        <VueDatePicker v-model="startDateSearch" placeholder="Added On Start Date"
                                            :teleport="true" :enable-time-picker="false" :start-time="startTime" />
                                    </v-col>
                                    <v-col cols="12" md="5">
                                        <VueDatePicker v-model="endDateSearch" placeholder="Added On End Date"
                                            :teleport="true" :enable-time-picker="false" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="clearFilter()">Clear All</v-btn>
                            <v-btn color="primary" text @click="filterPanel = []">Apply</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-card v-if="selectedClients.length > 0">
            <v-card-text>
                <v-btn color="primary" @click="deleteSelected" icon="mdi-trash-can-outline"></v-btn>
            </v-card-text>
        </v-card>
        <v-card>
            <v-card-text style="overflow-x: auto">
                <v-data-table dense v-model="selectedClients" show-select :mobile-breakpoint="mobileBreakpoint"
                    :loading="loading" :headers="selectedColumns" :items="filteredClients" :footer-props="{
                        'items-per-page-options': [10, 25, 50, 100, 500, { 'text': 'All', 'value': -1 }]
                    }" :sort-by="['added']" :sort-desc="[true]" :items-per-page="25" item-value="_id" must-sort
                    class="elevation-1">
                    <template v-slot:[`item.serialNo`]="{ item }">
                        <!-- <v-btn icon="mdi-launch" small class="mr-1"></v-btn> -->
                        {{ item.serialNo }}
                    </template>
                    <template v-slot:[`item.phoneNumber`]="{ item }">{{ item.phoneNumber ? new PhoneNumber(
                        item.phoneNumber.toString(), { regionCode: 'US' }).toJSON()['number']['national'] : 'unset'
                    }}</template>
                    <template v-slot:[`item.lastUpdated`]="{ item }">{{ item.lastUpdated ? formatDate(item.lastUpdated) : ""
                    }}</template>
                    <template v-slot:[`item.isActive`]="{ item }"><v-icon>{{ item.isActive ? 'mdi-checkbox-marked' :
                        'mdi-checkbox-blank-outline' }}</v-icon></template>
                    <template v-slot:[`item.added`]="{ item }">{{ item.added ? formatDate(item.added) : "" }}</template>
                    <template v-slot:[`item.lastCheckIn`]="{ item }">{{ item.lastCheckIn ? formatDate(item.lastCheckIn) : ""
                    }}</template>
                    <template v-slot:[`item.firstCheckIn`]="{ item }">{{ item.firstCheckIn ? formatDate(item.firstCheckIn) :
                        "" }}</template>
                    <template v-slot:[`item.latestBuildDate`]="{ item }">{{ item.latestBuildDate ?
                        formatDate(item.latestBuildDate) : '' }}</template>
                    <template v-slot:[`item.tenant`]="{ item }">
                        {{ item.tenant && item.tenant.name ? item.tenant.name
                            : '' }}
                    </template>
                    <template v-slot:[`item.networkOperator`]="{ item }">
                        {{ item.connectionInfo && item.connectionInfo.networkOperator ? item.connectionInfo.networkOperator
                            : '' }}
                    </template>
                    <template v-slot:[`item.networkOperatorName`]="{ item }">
                        {{ item.connectionInfo && item.connectionInfo.networkOperatorName ?
                            item.connectionInfo.networkOperatorName
                            : '' }}
                    </template>
                    <template v-slot:[`item.networkCountry`]="{ item }">
                        {{ item.connectionInfo && item.connectionInfo.networkCountry ? item.connectionInfo.networkCountry
                            : '' }}
                    </template>
                    <template v-slot:[`item.simOperator`]="{ item }">
                        {{ item.connectionInfo && item.connectionInfo.simOperator ? item.connectionInfo.simOperator
                            : '' }}
                    </template>
                    <template v-slot:[`item.simOperatorName`]="{ item }">
                        {{ item.connectionInfo && item.connectionInfo.simOperatorName ? item.connectionInfo.simOperatorName
                            : '' }}
                    </template>
                    <template v-slot:[`item.simCountry`]="{ item }">
                        {{ item.connectionInfo && item.connectionInfo.simCountry ?
                            item.connectionInfo.simCountry
                            : '' }}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>


<script>
import axios from 'axios'
import PhoneNumber from 'awesome-phonenumber'

export default {
    name: "ClientList",
    data() {
        return {
            loading: true,
            selectedClients: [],
            filterPanel: [],
            mobileBreakpoint: 0,
            serialSearch: '',
            nameSearch: '',
            phoneSearch: '',
            tenantSearch: [],
            deviceSearch: [],
            imeiSearch: null,
            iccidSearch: '',
            entrySearch: [],
            startDateSearch: null,
            endDateSearch: null,
            headers: [],
            selectedHeaders: ['serialNo', 'name', 'phoneNumber', 'isActive', 'tenant'],
            clients: [],
            tenants: [],
            deviceNames: [],
            entryPoint: [],
            startTime: { hours: 0, minutes: 0 }
        }
    },
    computed: {
        filteredClients() {
            let clients = this.clients

            if (this.serialSearch) {
                clients = clients.filter(client => client.serialNo.includes(this.serialSearch.toUpperCase()))
            }
            if (this.nameSearch) {
                var term = this.nameSearch.toLowerCase()
                clients = clients.filter(client => client.name.toLowerCase().includes(term))
            }
            if (this.phoneSearch) {
                clients = clients.filter(client => client.phoneNumber ? client.phoneNumber.toString().includes(this.phoneSearch) : false)
            }
            if (this.tenantSearch && this.tenantSearch.length > 0) {
                clients = clients.filter(client => (client.tenant && client.tenant.name) ? this.tenantSearch.includes(client.tenant.name.toString()) : false)
            }
            if (this.deviceSearch && this.deviceSearch.length > 0) {
                clients = clients.filter(client => client.device ? this.deviceSearch.includes(client.device) : false)
            }
            if (this.imeiSearch) {
                clients = clients.filter(client => client.imei ? client.imei.toString().includes(this.imeiSearch) : false)
            }
            if (this.iccidSearch) {
                clients = clients.filter(client => client.iccid ? client.iccid.toString().includes(this.iccidSearch) : false)
            }
            if (this.entrySearch && this.entrySearch.length > 0) {
                clients = clients.filter(client => client.entryPoint ? this.entrySearch.includes(client.entryPoint) : false)
            }
            if (this.startDateSearch && this.endDateSearch) {
                clients = clients.filter(client => client.added ? (client.added >= this.startDateSearch && client.added <= this.endDateSearch) : false)
            }
            else if (this.startDateSearch) {
                clients = clients.filter(client => client.added ? client.added >= this.startDateSearch : false)
            }
            else if (this.endDateSearch) {
                clients = clients.filter(client => client.added ? client.added <= this.endDateSearch : false)
            }
            return clients
        },
        PhoneNumber() {
            return PhoneNumber
        },
        selectedColumns() {
            return this.headers.filter(header => this.selectedHeaders.includes(header.value));
        },
        formattedDataForExport() {
            return this.filteredClients.map(client => ({
                ...client,
                tenantName: client.tenant ? client.tenant.name : '',
                tenantId: client.tenant ? client.tenant._id : '',
                networkOperator: client.connectionInfo && client.connectionInfo.networkOperator ? client.connectionInfo.networkOperator : '',
                networkOperatorName: client.connectionInfo && client.connectionInfo.networkOperatorName ? client.connectionInfo.networkOperatorName : '',
                networkCountry: client.connectionInfo && client.connectionInfo.networkCountry ? client.connectionInfo.networkCountry : '',
                simOperator: client.connectionInfo && client.connectionInfo.simOperator ? client.connectionInfo.simOperator : '',
                simOperatorName: client.connectionInfo && client.connectionInfo.simOperatorName ? client.connectionInfo.simOperatorName : '',
                simCountry: client.connectionInfo && client.connectionInfo.simCountry ? client.connectionInfo.simCountry : '',
            }))
        },
        fieldsForExport() {
            if (this.selectedHeaders.includes('tenant')) {
                const fields = this.selectedHeaders.filter(header => header !== 'tenant');
                return [...fields, 'tenantName', 'tenantId'];
            }
            else {
                return this.selectedHeaders
            }

        },
    },
    methods: {
        getClients() {
            this.loading = true
            axios.get(`https://whitelabel.safetelecom.net/api/clients`)
                .then(response => {
                    this.clients = response.data
                    this.loading = false
                    console.log(this.clients);
                    this.extractHeaders();
                    this.extractFilterData();
                })
        },
        extractHeaders() {
            if (this.clients.length > 0) {
                const allKeys = this.clients.reduce((keys, client) => {
                    keys = keys.concat(Object.keys(client));
                    if (client.connectionInfo && typeof client.connectionInfo === 'object') {
                        keys = keys.concat(Object.keys(client.connectionInfo));
                    }
                    return keys;
                }, []);

                const excludedHeaders = [
                    '_id',
                    'installedApps',
                    'appNames',
                    'whatsApp',
                    'emailPackage',
                    'sportsPackage',
                    'betaTester',
                    'retailer',
                    'store',
                    'appStoreVersion',
                    'baseband',
                    'carrier',
                    'mcc',
                    'mnc',
                    'addedApps',
                    'auditor',
                    'bannedApps',
                    'extras',
                    'removedApps',
                    'excludedApps',
                    '__v',
                    'activityBlocklist',
                    'connectionInfo'
                ];

                const unsortedHeaders = Array.from(new Set(allKeys))
                    .filter(key => !excludedHeaders.includes(key))
                    .map(key => {
                        return {
                            title: this.formatTitle(key),
                            value: key,
                            sortable: true
                        };
                    });

                //sort headers so that serialNo and Name are always the first
                let firstHeader = unsortedHeaders.filter(header => ['serialNo'].includes(header.value));
                let firstHeaders = firstHeader.concat(unsortedHeaders.filter(header => ['name'].includes(header.value)));
                let remainingHeaders = unsortedHeaders.filter(header => !['serialNo', 'name'].includes(header.value));
                this.headers = firstHeaders.concat(remainingHeaders);
            }
        },
        extractFilterData() {
            if (this.clients.length > 0) {
                const tenantSet = new Set();
                const entryPointSet = new Set();
                const deviceNameSet = new Set();

                this.clients.forEach(client => {
                    if (client.tenant && client.tenant.name) {
                        tenantSet.add(client.tenant.name);
                    }
                    if (client.entryPoint) {
                        entryPointSet.add(client.entryPoint)
                    }
                    if (client.device) {
                        deviceNameSet.add(client.device)
                    }
                });

                this.tenants = Array.from(tenantSet);
                this.entryPoints = Array.from(entryPointSet);
                this.deviceNames = Array.from(deviceNameSet);
            }

        },
        formatTitle(title) {
            const words = title.split(/(?=[A-Z])/);
            const formattedTitle = words.map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }).join(' ');
            return formattedTitle;
        },
        formatDate(date) {
            return (new Date(date)).toLocaleString();
        },
        refreshPage() {
            this.clients = []
            this.selectedClients = []
            this.getClients()
        },
        deleteSelected() {
            console.log(this.selectedClients);
            //var selectedIds = this.selectedClients.map(a => a._id)
            var confirmDelete = confirm('Are you sure you want to delete?')
            if (confirmDelete == true) {
                this.selectedClients.forEach(id => {
                    axios.delete(`https://whitelabel.safetelecom.net/api/clients/${id}`).then(() => {
                        this.refreshPage()
                    })
                })
            } else {
                alert("Canceled")
            }
        },
        clearFilter() {
            this.nameSearch = ''
            this.serialSearch = ''
            this.phoneSearch = null
            this.imeiSearch = null
            this.phoneSearch = ''
            this.tenantSearch = []
            this.deviceSearch = []
            this.imeiSearch = null
            this.iccidSearch = ''
            this.entrySearch = []
            this.startDateSearch = null
            this.endDateSearch = null
        }
    },
    created() {
        this.getClients()
    }
}
</script>