<template>
    <div>
        <v-toolbar dark color="light gray">
            <v-btn icon="mdi-arrow-left" to="/tenants"></v-btn>
            <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn v-if="this.editing" icon="mdi-delete" @click="deleteItem(currentItem._id)"><v-icon
                        icon="mdi-delete"></v-icon></v-btn>
                <v-btn @click="saveItem" icon="mdi-content-save"><v-icon icon="mdi-content-save"></v-icon></v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card flat style="height: 80vh; overflow-y: scroll;">
            <v-container grid-list-xl>
                <v-row wrap>
                    <v-col xs="12" md="6">
                        <v-text-field v-model="currentItem.name" label="Name"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-checkbox v-model="currentItem.isPremiumUI" label="Premium UI"></v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox v-model="currentItem.isABUpdates" label="AB Updates"></v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox v-model="currentItem.isForcedUpdates" label="Forced Updates"></v-checkbox>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import axios from "axios";

export default {
    name: "TenantDetail",
    data() {
        return {
            editing: true,
            loading: true,
            headers: [
                { text: "Edit", value: "edit", sortable: false },
                { text: "Name", value: "name" },
            ],
            items: [],
            apps: [],
            currentItem: {
                timestamp: null,
                version: 1,
                name: "",
            },
        };
    },
    computed: {
        formTitle() {
            return !this.editing ? "New Retailer" : "Edit Retailer";
        },
    },
    methods: {
        initData() {
            if (!this.editing) {
                this.currentItem = {
                    timestamp: null,
                    version: 1,
                    name: "",
                };
            } else {
                var link =
                    "https://whitelabel.safetelecom.net/api/tenants/" +
                    this.$route.params.tenant;
                axios.get(link).then((response) => {
                    this.currentItem = response.data;
                });
            }
            this.loading = false;
        },
        editItem(id) {
            this.editing = true;
            axios
                .get(`https://whitelabel.safetelecom.net/api/tenants/${id}`)
                .then((response) => {
                    this.currentItem = response.data;
                });
            this.dialog = true;
        },
        saveItem() {
            if (this.$route.params.tenant === "new") {
                axios
                    .post(`https://whitelabel.safetelecom.net/api/tenants`, this.currentItem)
                    .then(() => {
                        this.$router.push("/tenants");
                    });
            } else {
                axios
                    .put(
                        `https://whitelabel.safetelecom.net/api/tenants/${this.currentItem._id}`,
                        this.currentItem
                    )
                    .then(() => {
                        this.$router.push("/tenants");
                    });
            }
        },
        deleteItem(id) {
            confirm("Are you sure you want to delete this item?") &&
                axios
                    .delete(`https://whitelabel.safetelecom.net/api/tenants/${id}`)
                    .then(() => {
                        this.$router.push("/tenants");
                    });
        },
    },
    created() {
        if (this.$route.params.retailer === "new") {
            this.editing = false;
        }
        this.initData();
    },
}

</script>
  