<template>
  <v-app>
    <v-app-bar color="blue darken-3" dark app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span>SafeTelecom Admin</span>
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer">
      <!-- <v-list>
        <v-subheader>Account</v-subheader>
        <v-list-item>
          <v-list-item-avatar>
            <img :src="$auth.user.picture" alt="">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $auth.user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ $auth.user.email }}</v-list-item-subtitle>
            <v-list-item-subtitle>Access Level: {{ retailer.accessLevel }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
      <!-- <template>
				<div class="pa-2" v-if="!$auth.loading">
					<v-btn color="primary" block v-if="!$auth.isAuthenticated" @click="login">Login</v-btn>
					<v-btn color="primary" block v-if="$auth.isAuthenticated" @click="logout">Logout</v-btn>
				</div>
			</template> -->
      <v-divider></v-divider>
      <v-list>
        <v-list-subheader>Navigation</v-list-subheader>
        <v-list-item v-for="item in items" :key="item.text" :to="item.path">
          <v-list-item-title>
            <v-icon>{{ item.icon }}</v-icon>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      drawer: null,
      items: [
        { icon: 'mdi-account-multiple', text: 'Clients', path: '/clients' },
        { icon: 'mdi-store', text: 'Tenants', path: '/tenants' },
      ]
    }
  }
}
</script>
